import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import CFC from "../images/ally-CFC.png"
import GRP from "../images/ally-GRP.png"
import POR from "../images/ally-POR.png"
import OSE from "../images/ally-OSE.png"
import CEFF from "../images/ally-CEFF.png"
import URI from "../images/ally-URI.png"

const AlliesPage = ({ location }) => (
	<Layout location={location} title="Allies">
		<SEO title="Allies" />
		<section className="page">
			<div className="container text-white">
				<div className="content bg-black p-5">
					<div className="section-content">
						<div className="row">
							<div className="col-md-12 mb-3">
								<h2>Allies we work with!</h2>
							</div>
						</div>
						<div className="row text-center mt-2 mb-5">
							<div className="col-md-4">
								<a
									href="http://www.charterforcompassion.org"
									target="_blank"
									rel="noopener noreferrer"
									alt="Charter for Compassion"
								>
									<img
										src={CFC}
										alt="Charter for Compassion"
										className="img-fluid"
									/>
								</a>
							</div>
							<div className="col-md-4">
								<a
									href="https://www.goldenruleproject.org"
									target="_blank"
									rel="noopener noreferrer"
									alt="Golden Rule Project"
								>
									<img
										src={GRP}
										alt="Golden Rule Project"
										className="img-fluid"
									/>
								</a>
							</div>
							<div className="col-md-4">
								<a
									href="https://www.parliamentofreligions.org/"
									target="_blank"
									rel="noopener noreferrer"
									alt="Parliament of World Religions"
								>
									<img
										src={POR}
										alt="Parliament of World Religions"
										className="img-fluid"
									/>
								</a>
							</div>
						</div>
						<div className="row text-center mt-2 mb-5">
							<div className="col-md-4">
								<a
									href="https://www.orderofthesacredearth.org"
									target="_blank"
									rel="noopener noreferrer"
									alt="Order of the Sacred Earth"
								>
									<img
										src={OSE}
										alt="Order of the Sacred Earth"
										className="img-fluid"
									/>
								</a>
							</div>
							<div className="col-md-4">
								<a
									href="https://ceff.net/"
									target="_blank"
									rel="noopener noreferrer"
									alt="Colorado Environmental Film Festival"
								>
									<img
										src={CEFF}
										alt="Colorado Environmental Film Festival"
										className="img-fluid"
									/>
								</a>
							</div>
							<div className="col-md-4">
								<a
									href="https://uri.org/who-we-are/cooperation-circles"
									target="_blank"
									rel="noopener noreferrer"
									alt="United Religious Initiative"
								>
									<img
										src={URI}
										alt="United Religious Initiative"
										className="img-fluid"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</Layout>
)

export default AlliesPage
